// const backendUrl = process.env.REACT_APP_BE_URL;
// export default backendUrl;
// react won't accept env after build 
const url = window.location.href;
let backendUrl;
if (url.includes("3000")) {
    //local development
    backendUrl = "http://be.medipapel.com:8080";
} else if (url.includes("sandbox")) {
    backendUrl = "https://api.sandbox.medipapel.com";
} else if (url.includes("uat")) {
    backendUrl = "https://api.uat.medipapel.com";
} else {
    //production
    backendUrl = "https://api.medipapel.com"
}
console.log("url =>",backendUrl);

export default backendUrl;